// frappe.web_form.get_values();
// frappe.msgprint('Value must be more than 1000');
// frappe.web_form.get_value([fieldname]);
// frappe.web_form.set_value([fieldname], [value])
// frappe.web_form.set_field_property([fieldname], [property], [value]);
// frappe.web_form.set_field_value('rate', 'hidden', 1);
// frappe.web_form.get_value([fieldname]);

frappe.provide('spay');

spay.await_form_load = function(){
	// poll with 100 millsecs interval till form loaded
	return new Promise(function(resolve,reject){
		var count = 0
		var func1 = function(resolve){
			try{
				if (frappe.web_form_name.length &&
						$('form[data-web-form="issues"]')[0]){
					resolve(true);
				}
			}catch(e){
				func2(resolve)
			}
		}

		var func2 = function(resolve){
			setTimeout(()=>{func1(resolve)}, 100);
		}

		func2(resolve);
	})
}

spay.filter_select_options = function(resp){
	var com_opts = $('.form-group [data-fieldname="company"]');
	var prop_opts = $('.form-group [data-fieldname="property_unit"]');
	com_opts.children().remove();
	prop_opts.children().remove();

	if (resp){
		var estates = resp.estates;
		var p_units = resp.propert_units;	
		
		for (var i = estates.length - 1; i >= 0; i--) {
			com_opts.append(`<option value="${estates[i]}">${estates[i]}</option>`);
		}
		for (var i = p_units.length - 1; i >= 0; i--) {
			prop_opts.append(`<option value="${p_units[i]}">${p_units[i]}</option>`);
		}
	}
}

frappe.ready(function() {
	if ($('body').attr('data-path') == 'issues'){
		spay.await_form_load()
		.then(function(){
			console.log('loaded...')
			frappe.call({
				method:"simple_payments.utils.get_allowed_docs",
				callback: function(d){spay.filter_select_options(d.message);}
			});
		})
	}

});
